exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-auschwitz-js": () => import("./../../../src/pages/auschwitz.js" /* webpackChunkName: "component---src-pages-auschwitz-js" */),
  "component---src-pages-begleitprogramm-js": () => import("./../../../src/pages/begleitprogramm.js" /* webpackChunkName: "component---src-pages-begleitprogramm-js" */),
  "component---src-pages-cholera-js": () => import("./../../../src/pages/cholera.js" /* webpackChunkName: "component---src-pages-cholera-js" */),
  "component---src-pages-datenschutz-js": () => import("./../../../src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-fake-js": () => import("./../../../src/pages/fake.js" /* webpackChunkName: "component---src-pages-fake-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-judenbuche-js": () => import("./../../../src/pages/judenbuche.js" /* webpackChunkName: "component---src-pages-judenbuche-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-prolog-js": () => import("./../../../src/pages/prolog.js" /* webpackChunkName: "component---src-pages-prolog-js" */),
  "component---src-pages-rabbi-js": () => import("./../../../src/pages/rabbi.js" /* webpackChunkName: "component---src-pages-rabbi-js" */),
  "component---src-pages-shylock-js": () => import("./../../../src/pages/shylock.js" /* webpackChunkName: "component---src-pages-shylock-js" */),
  "component---src-pages-spielplan-js": () => import("./../../../src/pages/spielplan.js" /* webpackChunkName: "component---src-pages-spielplan-js" */),
  "component---src-pages-sponsoren-js": () => import("./../../../src/pages/sponsoren.js" /* webpackChunkName: "component---src-pages-sponsoren-js" */),
  "component---src-pages-theatermanifest-js": () => import("./../../../src/pages/theatermanifest.js" /* webpackChunkName: "component---src-pages-theatermanifest-js" */),
  "component---src-pages-tickets-js": () => import("./../../../src/pages/tickets.js" /* webpackChunkName: "component---src-pages-tickets-js" */)
}

