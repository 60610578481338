import React, {useState,useEffect} from 'react'
import {Button,Box,Heading,List,ListItem,Text, Flex, Drawer,DrawerOverlay,DrawerContent,DrawerBody,DrawerCloseButton,useDisclosure,VStack, Divider } from '@chakra-ui/react'
import {FaAlignJustify,FaHome} from 'react-icons/fa'
import {graphql,useStaticQuery} from 'gatsby'
import Link from './gatsbylink'
import Mlogo from './Logo50.png'
import {navigate} from 'gatsby'
//import MyContext from '../context/MyContext'
// //import {motion} from 'framer-motion'

// const MeinLogo = {
//   backgroundImage:`url(${Mlogo})`,
 
//   backgrundSize: 'contain',
 
//  backgroundRepeat: 'no-repeat'
// }

function DrawerExample() {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const btnRef = React.useRef()
  
    return (
      <Box maxW="1200">
      
        <Box cursor="pointer"  position="static" zIndex="200" right={[4,2,1]}    
       outline="none" 
        border="none" ref={btnRef}  onClick={onOpen}>
         
          <FaAlignJustify color='gray' fontWeight="300" fontSize="2.1rem" />
         
      
           
        </Box>
        
<Drawer 
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        finalFocusRef={btnRef}
        colorScheme ="whiteAlpha"
      >
        <DrawerOverlay>
          <DrawerContent pl={["0","0","4"]} ml="5"  minWidth={["320px","500px","700px"]}>
            <DrawerCloseButton />
            

            <DrawerBody bg="rgba(255,255,255,.9)">
            <Box bg="rgba(255,255,255,.35)" py="3">
            <List onClick={onClose} p={["3","2","2"]} width={["100%","90%"]}  mt="60px"  spacing="4" color="black.100"  >   
            <ListItem>
                 <Link  activeStyle={{opacity:.5, backgroundColor:"white", color:"black", padding:1}} to="/"><FaHome fontSize="1.3rem" /></Link>
             </ListItem> 
             <ListItem>
                 <Link  activeStyle={{opacity:.5, backgroundColor:"white", color:"black", padding:1}} style={{fontSize:"1.3rem",fontWeight:"500"}} to="/tickets">Tickets &amp; Preise<span style={{fontWeight:"200"}}><br/>Vor Ort &amp; Online</span></Link>
             </ListItem> 
             <ListItem>
                 <Link  activeStyle={{opacity:.5, backgroundColor:"white", color:"black", padding:1}} style={{fontSize:"1.3rem",fontWeight:"500"}} to="/spielplan">Spielplan &amp; <span style={{fontWeight:"200"}}><br/>Theaterfestival 2022</span></Link>
             </ListItem>  
             <ListItem>
                 <Link  activeStyle={{opacity:.5, backgroundColor:"white", color:"black", padding:1}} style={{fontSize:"1.3rem",fontWeight:"500"}} to="/sponsoren">Sponsoren &amp; <span style={{fontWeight:"200"}}><br/>Theaterfestial 2022</span></Link>
             </ListItem>
             <ListItem>
                 <Link  activeStyle={{opacity:.5, backgroundColor:"white", color:"black", padding:1}} style={{fontSize:"1.3rem",fontWeight:"500"}} to="/prolog">Prolog<span style={{fontWeight:"200"}}><br/>Wir festspielen</span></Link>
             </ListItem>
             <ListItem>
                 <Link  activeStyle={{opacity:.5, backgroundColor:"white", color:"black", padding:1}} style={{fontSize:"1.3rem",fontWeight:"500"}} to="/rabbi">Der Rabbi von Bacharach<span style={{fontWeight:"200"}}><br/>Stationen eines Traumas</span></Link>
             </ListItem>   
             <ListItem>
                 <Link  activeStyle={{opacity:.5,backgroundColor:"white ", color:"black", padding:1}} style={{fontSize:"1.3rem",fontWeight:"500"}} to="/cholera">

Heine. Ich rede von der Cholera<span style={{fontWeight:200}}><br/>Epidemische Krisen: Nichts Neues</span></Link>
             </ListItem>
             <ListItem >
                 <Link  activeStyle={{opacity:.5,backgroundColor:"white", color:"black",padding:1}}  style={{fontSize:"1.3rem",fontWeight:"500", letterSpacing:".09rem"}} to="/auschwitz">In Auschwitz gab es keine Vögel<span style={{fontWeight:"200"}}><br/>Konzert-Lesung mit Monika Held &amp; Gregor Praml</span></Link>
             </ListItem>
             {/* <ListItem>
                 <Link  activeStyle={{opacity:.5,backgroundColor:"white", color:"black", padding:1}} style={{color:"red.100", backgroundColor:"gray.100", fontSize:"1.3rem",fontWeight:"500"}} to="/widerhall">Widerhall<span style={{fontWeight:"200"}}><br/>1963 beginnt in Frankfurt ...</span></Link>
             </ListItem> */}
             <ListItem>
                 <Link  activeStyle={{opacity:.5,backgroundColor:"white", color:"black",padding:1}} style={{color:"red.100", backgroundColor:"gray.100", fontSize:"1.3rem",fontWeight:"500", letterSpacing:".09rem"}} to="/shylock">Podium Szene + Dialog<br/><span style={{fontWeight:200}}>Shylock erklärt Shylock</span></Link>
             </ListItem>
             <ListItem>
                 <Link  activeStyle={{opacity:.5,backgroundColor:"white", color:"black",padding:1}} style={{color:"red.100", backgroundColor:"gray.100", fontSize:"1.3rem",fontWeight:"500", letterSpacing:".09rem"}} to="/fake">Podium Szene + Dialog<br/>Sankt Werner<br/><span style={{fontWeight:200}}>700 Jahre antisemitische Fake News</span></Link>
             </ListItem>
             <ListItem>
                 <Link  activeStyle={{opacity:.5,backgroundColor:"white", color:"black",padding:1}} style={{color:"red.100", backgroundColor:"gray.100",fontSize:"1.3rem",fontWeight:"500", letterSpacing:".09rem"}} to="/judenbuche">Die Judenbuche<span style={{fontWeight:200}}><br/>Ein Kriminalfall aus der Zeit der Romantik</span></Link>
             </ListItem>
             
             <ListItem>
                 <Link  activeStyle={{opacity:.5,backgroundColor:"white", color:"black "}} style={{fontSize:"1.3rem",fontWeight:"500", letterSpacing:".09rem"}} to="/theatermanifest">Manifest<span style={{fontWeight:200}}><br/>An den Ufern der Poesie</span></Link>
             </ListItem>
             <ListItem>
                 <Link  activeStyle={{opacity:.5,backgroundColor:"white", color:"black "}} style={{fontSize:"1.3rem",fontWeight:"500", letterSpacing:".09rem"}} to="/begleitprogramm">Begleitprogramm<span style={{fontWeight:200}}><br/>Download Programmhefte</span></Link>
             </ListItem>
             <Divider orientation="horizontal"/>
             <ListItem>
                 <Link  activeStyle={{opacity:.5}} style={{fontSize:"1.3rem",fontWeight:"500", letterSpacing:".09rem"}} to="/kontakt">Kontakt</Link>
             </ListItem>
             <ListItem>
                 <Link  activeStyle={{opacity:.5}} style={{fontSize:"1.3rem",fontWeight:"500", letterSpacing:".09rem"}} to="/impressum">Impressum</Link>
             </ListItem>
             <ListItem>
                 <Link  activeStyle={{opacity:.5}} style={{fontSize:"1.3rem",fontWeight:"500", letterSpacing:".09rem"}} to="/datenschutz">Datenschutz</Link>
             </ListItem>
             </List>
             </Box>
             </DrawerBody>

          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
      </Box>
  )
}

export default function Header() {

  const [agree,setAgree]=useState(true)
  //const {eng,setEng} = React.useContext(MyContext)

 useEffect(()=>{
  if(!typeof window) return
  let wflag = window.localStorage.getItem("theaagree")
 
  if(wflag===null|| wflag===true){
    setAgree(false)
    
  }

 },[agree,setAgree])




 function org2daten(){
  
  setAgree(!agree)
 

 // window.localStorage.setItem("sehen",true)
  window.localStorage.setItem("theaagree",true)
  navigate("/")
 }

  function Datenschutz(){
    return(
      <Flex minWidth="320" position="absolute" top="0" backgroundColor="white"   margin="auto"  zIndex="800"  mt="4"  bg="gray.800"  left="100" right="100" flexDirection="column" justifyContent="center" maxWidth="900">
       <Box p="4"  >  
      <Text fontSize={["md","lg"]} color="white">
        Bitte machen Sie sich mit unseren Datenschutzbestimmungen vertraut: <br/>
        <Link  activeStyle={{opacity:.5}} style={{color:"white"}} to="/datenschutz">&rarr; Unsere Datenschutzbestimmungen</Link>
        <Button color="white" role="pushbutton" m="3" p="2"  variant="solid"  size="sm" onClick={()=> org2daten()}>Ich stimme zu</Button>
      </Text>
      </Box>
    </Flex>
    )
    
 
  }
   const data=useStaticQuery(graphql`
    query HeaderQuery {
      site {
        siteMetadata {
          description
          title
        }
      }
      
                poesie:file(relativePath: {eq: "web/poesie2.png"}) {
                  childImageSharp {
                     fluid(maxWidth:1400) {
                               ...GatsbyImageSharpFluid
                             }
                          }
                      }        
           }`)
  
    return(
        <header>
        <Flex 
       
        
        flex="1" 
        mb="2"
        pt="3"
        px={["2","3"]}
        pb="19" 
        margin="auto"
        maxWidth="1200"
        // maxWidth={["320px","560px","750px","900px","1000px"]}
        justifyContent="flex-end"
        alignItems="space-between"
        >
        <VStack cursor="pointer" onClick={()=>navigate("/")} width={["10vw","17vw"]} maxWidth="200px" maxHeight="15vh"><img  src={Mlogo} alt="Logo"/></VStack>
        <Box py="0" px={["2","3","3"]} maxHeight="250px" overflow="hidden"  flex="1">
            <Heading cursor="pointer" onClick={()=>navigate("/")}  p="0" m="0" as="h1" fontWeight="400" color="text"  fontSize={["xl","2xl","3xl","5xl"]}>
             
          {data.site.siteMetadata.title} 
            
               
            </Heading>
            <Box mt="1" onClick={()=>navigate("/")} flex="1" as="button" aria-label="back home" role="pushbutton" variant="unstyled" outline="none" textAlign="left" border="none">
            <Text m="1" p="2"  color="text" fontWeight="400" marginTop="-10" mb="0">Theaterfestival im UNESCO Welterbe Oberes Mittelrheintal <br/>1.07 - 17.7.2022</Text>
           
         
      </Box>
         
       </Box>

       
       {!agree &&
        <Box >    <Datenschutz /></Box>
    
        }
  
       <Box p="2" ><DrawerExample/></Box>
   
        
    </Flex>
    </header>
    )
    
}
