import { ChakraProvider,extendTheme} from '@chakra-ui/react'
import SimpleReactLightbox from 'simple-react-lightbox'
import React from 'react'
import "@fontsource/roboto/500.css" 
import "@fontsource/roboto/300.css" 

import Layout from './components/layout'

const Heading = {
  // The styles all button have in common
  baseStyle: {
    fontWeight: 400,
    margin:"10px 0",
    color:"text",
    padding:0,
    lineHeight:'1.1',
    fontSize:[18,22],
   
  },
  
 
  /* sizes: {
    sm: {
      fontSize: "16px",
      
    },
    md: {
      fontSize: "20px",
     
      
    },
    xl: {
      fontSize: "22px",
     
      
    },
   
    lg: {
      fontSize: "19px",
     
      
    },
  }, */
  
  variants: {
    outline: {
     fontWeight:300,
    },
    solid: {
    
      color: "text",
    },
    title: {
   
     padding:0,
     marginTop:4,
    textAlign:'left',
    fontWeight:400,
    fontFamily:'Roboto',
    fontSize: ["lg","xl","2xl"],
    
    },
    htitle: {
      fontSize: ["xl","2xl","2xl"],
      pt:10,
    
      letterSpacing:".05rem",
      pl:4,
      
   
     },
     titel: {
      fontSize:["md","lg","2xl","3xl"],

     },

    annonce: {
      fontSize:["lg","xl","xl"],
      lineHeight:"1.3",
      fontWeight:300,
      
      color: "blue.400"
    },
  },
  // The default size and variant values
  defaultProps: {
   
   // variant: "outline",
    // margin:0,
    // padding:0,
  },
}



const Text = {
  
  baseStyle: {
    fontWeight: 300,
    color:"text",
    fontFamily: 'Roboto,sans-serif',
    fontSize:[16,18],
    my:5,
  },
 
  variants: {
    outline: {
      border: "none",
     
    },
  zitat:{
    my:"30px",
    fontSize:[20,25],
    px:[4,5,8,10],
    lineHeight:1.41,
   
    fontStyle: "italic",
    
  },
  MProg:{
    color:'green',
    fontSize: ["sm","md"]
  },
 Bild:{
    color:'gray.800',
    fontSize: "md",
    py:1,
    mt: -1,
  },
   Title:{
    fontWeight:400,
    fontSize:["lg","xl"]
   },
  },
 
  defaultProps: {
    fontStyle:"xl",
    variant: "outline",
    fontFamily:'Roboto,sans-serif',
    
  },
}
const ListItem = {
  
  baseStyle: {
    fontWeight: 300,
    fontSize:[19,21,22],
    margin:"10px 0",
    color:"black",
    fontFamily: 'Roboto,sans-serif'
  },
  sizes: {
    sm: {
      fontSize: "16px",
    },
    md: {
      fontSize: "18px",
    },
    lg: {
      fontSize: "20px",
   },
    xl: {
      fontSize: "24px",
      
    },
  },
 
  variants: {
    outline: {
      border: "none",
     
    },
  
  LProg:{
    color:'green',
    fontSize: ["sm","md"]
  }
   
  },
 
  defaultProps: {
  
    variant: "outline",
    fontFamily:'Roboto,sans-serif'
  },
}
const Button = {
  // The styles all button have in common
  baseStyle: {
    fontWeight: "500",
    textTransform: "uppercase",
    margin:"10px",
    color:"red",
 
  },
  // Two sizes: sm and md
  sizes: {
    sm: {
      fontSize: "12px",
      padding: "6px",
    },
    md: {
      fontSize: "16px",
      padding: "24px",
      
    },
  },
  // Two variants: outline and solid
  variants: {
    outline: {
      border: "2px solid",
      borderColor: "blue.600",
      fontSize: "11px",
      padding:"1",
      margin:"0"
    },
    solid: {
      bg: "gray.400",
      color: "white",
      border: "none",
      p:"0",
      m:"1",
      _hover:{
        backgroundColor:"blue.400"
      }
    },
  },
  // The default size and variant values
  defaultProps: {
    size: "8px",
    variant: "solid",
   
  },
}
const theme= extendTheme({
  styles: {
    global: {
      fontFamily:'Roboto,sans-serif',
      ".mdx-prose": {
        h1: {
          fontSize: "4xl",
          mb: "4",
        },
        p: {
          fontSize: "sm",
          lineHeight: "1.4",
        },
      },
    },
  },
    fonts: {
        heading: "Roboto,sans-serif",
        body: "Roboto,sans-serif"
    },
   components:{
    Button,
    Text,
    Heading,
    ListItem,
 
   },
    textStyles: {
        // h1: {
        //   // you can also use responsive styles
        //   fontSize: ["38px","38px","45px","52px"],
          
        //   lineHeight: "110%",
        //   letterSpacing: "-2%",
        //   color:"blue.400"
        // },
        // h2: {
        //   fontSize: ["18px", "24px","26px", "38px"],
        //    fontWeight:100,
        //   lineHeight: "110%",
        //   letterSpacing: "-1%",
        //   padding:'30px 0'
        // },
        // mestyle: {
        //   fontSize: ["16px","24px","38px"],
        //   color: "teal",
        //   pt:"4",
        //   pb:"2",
          
        //   letterSpacing:".09rem"
        // },
        p: {
         
          color: "gray.600"
        },
        li:{
         py:"4",
         my:"2"
        }
        }
})

export const wrapPageElement = ({ element }) => {
   
  return (

    <ChakraProvider theme={theme}  resetCSS>
       
<Layout><SimpleReactLightbox>{element}</SimpleReactLightbox> </Layout>
     
     
    </ChakraProvider>
  )
}