import React, {useState} from 'react'
import {Flex,Box,Stack} from '@chakra-ui/react'
import Header from './header'
import { FaInstagram,FaFacebook } from 'react-icons/fa'
import LinksBild from './streifen_links.gif'
import RechtsBild from './streifen_rechts.gif'
//import {motion} from 'framer-motion'
import MyContext from '../context/MyContext'

const lBild = {
    backgroundImage: `url(${LinksBild})`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
 
    marginTop:60,
     marginLeft:3

}
const rBild = {
    backgroundImage: `url(${RechtsBild})`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    marginTop:60,
    marginRight:3
}



export default function Layout({children}){
const [eng,setEng] = useState(false)





 
    return(
        <>
       <MyContext.Provider value= {{eng,setEng}}>
       <Header/>
       
     <Flex p="1" m="2" bg="white.900" flex="1" margin="auto" maxWidth="1200px">

<Box  width={['60px',"70px","90px"]} style={lBild}></Box>
 
      <Box>  
     
     {children}

     </Box>
    
<Box  width={['60px',"70px","90px"]} style={rBild}></Box>
   
     </Flex>
     <Stack height="49px" justifyContent="center" direction="row">
     <Box p="2" height="50px" width="50px"><a aria-label="open" rel="noopener noreferrer" target="_blank" href="https://www.facebook.com/THEATER-WILLY-PRAML-146563365411077/"><FaFacebook width="50px" height="50px" fontSize="2.2rem"  /></a></Box>
     <Box p="2" height="50px" width="50px"><a aria-label="open" rel="noopener noreferrer" target="_blank" href="https://www.instagram.com/theaterwillypraml_frankfurt/?hl=dee"><FaInstagram  width="50px" height="50px"fontSize="2.2rem" /></a></Box>
     <Box height="60px" width="60px"><a aria-label="open" rel="noopener noreferrer" target="_blank" href="https://www.markt1-bacharach.de"><img src="/markt1.png" width="70px" height="70px" alt="Logo von Kulturraum Markt 1" /></a></Box>
     <Box height="60px" width="60px"><a aria-label="open" rel="noopener noreferrer" target="_blank" href="https://kulturnetz-oberes-mittelrheintal.org"><img src="/komm.png" width="55px" height="55px"alt="Logo von K.O.M.M"  /></a></Box>
     
    
     </Stack>
  
    
      
      
     </MyContext.Provider>
       
        </>
    )
}